<template>
<div>
  <h2 class='pb-2' :class="[`text-${headingSize}`,`text-${headingColor}`]">{{heading}}</h2>
  <p class='text-cdlblack leading-normal' :class="[`text-${textSize}`,`text-${textColor}`]" v-if="text">{{text}}</p>
  <p class='text-cdlblack leading-normal' :class="[`text-${textSize}`,`text-${textColor}`]" v-if="!text"><slot></slot></p>
  </div>
</template>

<script>
export default {
  name: "HeadingAndText",
  props: {
    heading: {
      type: String,
      default: ''
    },
    headingSize: {
      type: String,
      default: '3xl'
    },
    headingColor: {
      type: String,
      default: 'cdlpink'
    },
    text: {
      type: String,
      default: ''
    },
    textSize: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: 'cdlblack'
    }
  },
  components: {},
  methods: {},
}
</script>
