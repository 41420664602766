<template>
  <div class="box w-full pb-8 md:pb-0">
    <BaseContainer class="pt-6 cont">
      <div class="wrap sm:text-center">
        <div class="pb-8">
          <span class="font-heading font-bold text-white leading-3"
            >1st collection</span
          >
          <h2 class="text-white leading-6 pt-2">3000 Cuddle Dogs</h2>
        </div>

        <div class="scrollable w-full overflow-x-scroll">
          <!-- labels -->
          <!-- <div
            class="labels flex flex-row items-center justify-center w-full pb-2"
          >
            <div
              v-for="label in allocationLabels"
              :key="label.id"
              class="label flex flex-col justify-center items-center w-full"
              :class="[`${label.id}`, {running: label.running}]"
            >
              <span class="label-date">{{ label.date }}</span>
              <span class="label-label font-heading">{{ label.label }}</span>
            </div>
          </div> -->

          <!-- bars -->
          <div class="fullbar flex flex-row">
            <AllocationBar
              v-for="allocation in allocations"
              :key="allocation.id"
              :data="allocation"
              :allocationSum="allocationSum"
            />
          </div>

          <!-- times -->
          <p class="times pt-8 pb-0 text-xs">
            <!-- *9a-12a PST (San Francisco) | 12a-03p EST (New York) | 6a-9a CET
            (Prague) | 9p-12a GST (Dubai) | 1p-4p PST (Manila) | 4p - 7p AED
            (Sydney) -->
          </p>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import AllocationBar from "./AllocationBar.vue"
export default {
  name: "Allocation",
  mixins: [mixinInitLoader],
  components: {
    AllocationBar,
  },
  data() {
    return {
      allocationLabels: [
        {
          // id: "presale",
          id: 'prelaunch',
          label: "prelaunch",
          // date: "28 nov*",
          running: false,
        },
        {
          id: "raffle",
          label: "public raffle",
          // date: "30 nov*",
          running: false,
        },
        {
          id: "raffle-winners",
          label: "raffle winners",
          // date: "01 dec*",
          running: false,
        },
        {
          id: "mint",
          label: "mint",
          // date: "02 dec*",
          running: false,
        },
        {
          id: "reveal",
          label: "post launch",
          // date: "04 dec*",
          running: false,
        },
      ],
      allocations: [
        {
          id: "prelaunch",
          label: "prelaunch",
          allocation: 700,
          used: 0,
          running: true,
        },
        // {
        //   id: "presale-private",
        //   label: "private",
        //   allocation: 100,
        //   used: 0,
        //   running: true,
        // },
        // {
        //   id: "presale-public",
        //   label: "public",
        //   allocation: 480,
        //   used: 0,
        //   running: true,
        // },
        // {
        //   id: "marketing",
        //   label: "marketing",
        //   allocation: 120,
        //   used: 0,
        //   running: true,
        // },
        {
          id: "public-mint",
          label: "public",
          allocation: 2000,
          used: 0,
          running: false,
        },
        {
          id: "impact-nfts",
          label: "impact nfts",
          allocation: 300,
          used: 0,
          running: false,
        },
      ],
    }
  },
  computed: {
    allocationSum() {
      return this.allocations.reduce((a, b) => +a + +b.allocation, 0)
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  // background: rgba(204, 89, 177, 0.58);
  background: rgba(0, 0, 0, 0.58);
  backdrop-filter: saturate(180%) blur(10px);

  @media (min-width: theme("screens.sm")) {
    background: none;
    backdrop-filter: none;
  }

  .wrap {
    @media (min-width: theme("screens.sm")) {
      // background: rgba(204, 89, 177, 0.58);
      background: rgba(0, 0, 0, 0.58);
      backdrop-filter: saturate(180%) blur(10px);
      border-radius: 10px;
      padding: 20px;
    }

    .fullbar,
    .times,
    .labels {
      min-width: 1000px;
    }

    .fullbar {
      background: rgba(162, 128, 153, 0.5);
      border-radius: 11px;
      padding: 5px;
      gap: 4px;
    }

    .times {
      color: rgba(174, 128, 164, 1);
    }

    .scrollable {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .labels {
      .label {
        position: relative;
        &.running {
          .label-label {
            color: #fff;
          }
        }

        &.presale {
          width: 125%;
          // border: 1px solid #ff0000;

          &::before {
            content: "";
            border-bottom: 1px solid #492740;
            width: 30%;
            position: absolute;
            bottom: 11px;
            right: 0;
          }

          &::after {
            content: "";
            border-bottom: 1px solid #492740;
            width: 30%;
            position: absolute;
            bottom: 11px;
            left: 0;
          }
        }

        .label-date {
          font-size: 0.65rem;
          color: #fff;
          letter-spacing: 1.07px;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
        }
        .label-label {
          color: #492740;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
