import Vue from "vue"

// also in store/index.js
if (
  ["local_dev", "development", "staging"].includes(
    process.env.VUE_APP_ENVIRONMENT
  ) ||
  process.env.VUE_APP_FORCE_DEBUG == "true"
) {
  Vue.config.devtools = true
  Vue.config.productionTip = false
  Vue.config.silent = false
} else if (["production"].includes(process.env.VUE_APP_ENVIRONMENT)) {
  Vue.config.devtools = false
  Vue.config.productionTip = false
  Vue.config.silent = false
}

import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueGtag from "vue-gtag"
import checkView from '@/directives/checkView'
import VueAnime from 'vue-animejs'
import OnoffToggle from 'vue-onoff-toggle'
import "./assets/tailwind.css"
import ScrollAnimation from './directives/scrollanimation'

Vue.directive('scrollanimation', ScrollAnimation);

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  }
}, router)
Vue.use(OnoffToggle)
Vue.use(checkView)
Vue.use(VueAnime)

// register base components globally so we don't have have to import them manually
const requireComponent = require.context('@/components/base', true, /\w+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = 'Base' + fileName.split('/').pop().replace(/\.\w+$/, '')
  // console.log('registering component:', componentName)
  Vue.component(componentName, componentConfig.default)
})

// screen resize listener
store.dispatch('client/updateViewportSize') // on load
window.addEventListener("resize", () => {
  store.dispatch('client/updateViewportSize')
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
