<template>
  <div
    class="
      box
      px-4
      pt-3
      pb-4
      bg-cdlgrass-light
      rounded-xl
      mt-0
      cursor-pointer
      hover:shadow-lg
      transition-all
      ease-in-out
      duration-500
    "
    @click="toggle"
  >
    <div class="relative">
      <h3 class="text-2xl pb-2 text-cdlpink text-left pr-0 break-words">{{ content.heading }}</h3>
      <!-- <IconCircleArrowDown
        class="
          w-7
          fill-current
          text-cdlgrass-dark
          hover:opacity-50
          transition-all
          ease-in-out
          duration-200
          absolute
          top-0
          right-0
        "
        :class="[{'transform rotate-180': expanded}]"
      /> -->
    </div>
    <p class="leading-relaxed text-left" v-if="content.contentShort">
      {{ content.contentShort }}
    </p>
    <p class="leading-relaxed mt-2 text-left" v-if="expanded">
      {{ content.contentExpanded }}
    </p>
  </div>
</template>

<script>
import IconCircleArrowDown from "@/assets/icon-circle-arrow-down.svg?inline"

export default {
  name: "ExpandableBox",
  props: {
    content: {
      type: Object,
    },
  },
  components: {
    IconCircleArrowDown,
  },
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    toggle() {
      console.log("toggle")
      this.expanded = !this.expanded
    },
  },
}
</script>

<style></style>
