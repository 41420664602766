<template>
  <div class="allocation relative" :class="{'running': data.running}" :style="styleAllocation">
    <div class="bar" :style="styleBar"></div>
    <div class='labelUsage absolute inset-0 flex justify-center items-center' :class="[{'flex-col leading-3': data.allocation <= 200}]"><span>{{data.used}}</span><span class='slash'>/</span><span>{{data.allocation}}</span></div>
    <div class='labelType'><span>{{data.label}}</span></div>
  </div>
</template>

<script>
export default {
  name: "AllocationBar",
  props: {
    data: {
      type: Object,
    },
    allocationSum: {
      type: Number,
    },
  },
  data() {
    return {}
  },
  computed: {
    styleAllocation() {
      let s = {}

      s.width = (100 / this.allocationSum) * this.data.allocation
      s.width += "%"
      return s
    },
    styleBar() {
      let s = {}

      s.width = (100 / this.data.allocation) * this.data.used
      s.width += "%"
      return s
    },
  },
}
</script>

<style lang="less" scoped>
.allocation {
  height: 40px;
  width: 20%;
  background: rgba(174, 128, 164, 0.75);
  // backdrop-filter: saturate(180%) blur(10px);
  border-radius: 8px;

  &.running {
    background: #EDC4E4;
    .bar {
      min-width: 8px;
    }
  }

  .bar {
    height: 40px;
    background: #cc59b1;
    border-radius: 8px;
  }

  .labelUsage {
    font-size: 0.75rem;
    color: #fff;
    
      overflow-wrap: anywhere;

      .slash {
        color: #fff;
      }
    
  }

  .labelType {
    margin-top: 10px;
    font-size: 0.65rem;
    // color: #492740;
    color: #fff;
    letter-spacing: 1.07px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
</style>
