<template>
  <div class="cont relative overflow-hidden pb-36 pt-20 z-30">
    <BaseContainer class="text-center">
      <h3 class="text-cdlpink text-6xl leading-none">JOIN US TODAY!</h3>
      <p class='pt-4 pb-6'>Become part of better TOMORROW for those in need</p>
      <div class='circles w-full flex flex-col justify-center items-center mt-8 md:flex-row gap-10'>
        <div class='circle text-center w-64 h-64 p-4 md:pt-16 flex flex-col items-center justify-center md:justify-start'>
          <!-- <span class='text-white font-bold text-2xl pb-4'>1</span> -->
          <h4 class='font-bold text-2xl'>Donate</h4>
          <p>to impact fund and make sure the donations are reaching the right place in transparent Open Data available for public review.</p>
          <!-- <BaseButton outline class='mt-4' size="xs">choose shelter</BaseButton> -->
        </div>
        <div class='circle text-center w-64 h-64 p-4 md:pt-16 flex flex-col items-center justify-center md:justify-start -mt-8 md:mt-0 md:-ml-8'>
          <!-- <span class='text-white font-bold text-2xl pb-4'>2</span> -->
          <h4 class='font-bold text-2xl'>Follow us</h4>
          <p>and our partners on social media, help us to gain an audience, the more we are known, the more we can help!</p>
        </div>
        <div class='circle text-center w-64 h-64 p-4 md:pt-16 flex flex-col items-center justify-center md:justify-start -mt-8 md:mt-0 md:-ml-8'>
          <!-- <span class='text-white font-bold text-2xl pb-4'>3</span> -->
          <h4 class='font-bold text-2xl'>Spread</h4>
          <p>the message, and share a cuddle. Become a part of the positive change. With just a little a lot can be achieved.</p>
          <BaseButton outline class='mt-4' size="xs" @click="onClickTweet()"><IconTwitter class='text-black children:fill-current w-4 mr-1' /> about us!</BaseButton>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import IconTwitter from '@/assets/icon-twitter.svg?inline'

export default {
  name: "JoinCuddle",
  mixins: [mixinInitLoader],
  components: {IconTwitter},
  data() {
    return {
      tweetText: 'Join the effort of Cuddle.me impact fund! From donations to shelters, to co-funding animal treatment, to support of research and development of effective animal medicine.'
    }
  },
  methods: {
    onClickTweet() {
      window.open(`https://twitter.com/intent/tweet?text=${this.tweetText}`, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.cont {
  // .circle {
  //   background: rgba(255,194,158,0.15);
  //   border-radius: 50%;
  // }
}
</style>
