<template>
  <section class="hero cont" v-view="onAppearInViewport">
    <BaseContainer class="relative z-50 pb-40 md:pb-32 lg:pb-60 xl:pb-80">
      <HeroIntro
        :title="hero.title"
        :subtitle="hero.subtitle"
        :cta="hero.cta"
      />
    </BaseContainer>

    <div class="grass-wrap">
      <BaseContainer class="relative">
        <div class="animals-bowl">
          <img class="dog-bowl" :src="imgDogBowlPink" />
        </div>
      </BaseContainer>
      <GrassTop class="grass-top" />
      <div class="grass-color" />
    </div>
  </section>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import HeroIntro from '@/components/common/HeroIntro'
import CuddleLogo from "@/assets/cuddle_logo_color.svg?inline"
import Countdown from "./Hero/Countdown.vue"
import Allocation from "./Hero/Allocation.vue"
import GrassTop from "@/assets/grass-top.svg?inline"
import ImageDogBowlPink from "@/assets/images/svg/raw/dog-bowl-pink.svg?raw"

export default {
  name: "Hero",
  mixins: [mixinInitLoader],
  components: {
    HeroIntro,
    CuddleLogo,
    Countdown,
    Allocation,
    GrassTop,
    ImageDogBowlPink,
  },
  data() {
    return {
      showZorro: false,
      hero: {
        title: "Crypto For Good<br>Donating transparently",
        subtitle:
          "Connecting charity with the cryptoverse.",
        cta: {
          url: "https://app.dogeswap.org/#/swap?outputCurrency=0x63041cD8038AFF977FC98F972Cc4498a3E4d6342",
          label: "Buy $CUDL here",
          // ukraine: true,
          // event: 'landing_get_your_hero_of_ukraine_click'
          event: 'landing_buy_cudl_here_click'
        },
      },
    }
  },
  computed: {
    imgDogBowlPink() {
      return ImageDogBowlPink
    },
  },
  mounted() {},
  methods: {
    onAppearInViewport(e) {
      if (e.type == "enter") this.showZorro = true
      if (e.type == "exit") this.showZorro = false
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";

section.hero {
  position: relative;
  // padding-top: 90px;
  background: theme("colors.cdlpeach.DEFAULT") url("../../assets/images/clouds.png") repeat 0 0;
  animation: 28s linear 0s normal none infinite animateClouds;
  overflow: hidden;
  margin-bottom: -1px;
}

.grass-wrap {
  position: relative;

  .animals-bowl {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: calc(-20px + 5vw);
    // width: 375px;
    width: 100vw;
    // outline: 1px #ff0000 solid;

    @media (min-width: @bp-md) {
      width: 50%;
      bottom: calc(-40px + 5vw);
      margin-right: 10px;
    }

    .animals {
      width: 100%;
      z-index: 0;

      // @media (min-width: @bp-md) {
      //   width: 400px;
      //   margin-right: 60px;
      //   bottom: -10px;
      // }

      // @media (min-width: @bp-lg) {
      //   width: calc(500px + 10vw);
      //   bottom: calc(100px - 1vw);
      //   right: calc(-50px - 10vw);
      // }
    }

    .dog-bowl {
      position: absolute;
      width: 60%;
      left: 0;
      right: 0;
      bottom: -70px;
      margin: 0 auto;
      z-index: 40;
      transform: rotate(6deg);

      // @media (min-width: @bp-md) {
      //   width: 180px;
      //   margin-right: 170px;
      //   bottom: -60px;
      // }

      // @media (min-width: @bp-lg) {
      //   width: 260px;
      //   bottom: -20px;
      //   margin-right: 130px;
      // }
    }
  }
  .grass-top {
    position: absolute;
    bottom: -30px;
    left: -40px;
    width: 300vw;
    z-index: 30;

    @media (min-width: @bp-md) {
      width: 130vw;
      left: 0;
      bottom: 30px;
    }

    @media (min-width: @bp-lg) {
      bottom: -10px;
    }
  }
  .grass-color {
    background-color: theme("colors.cdlgrass.DEFAULT");
    height: 20px;
    width: 100vw;

    @media (min-width: @bp-md) {
      height: 60px;
    }

    @media (min-width: @bp-lg) {
      height: 0px;
    }
  }
}

@keyframes animateClouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1450px 0;
  }
}
</style>
