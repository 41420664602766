<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-row items-center">
    <OnoffToggle
      v-model="enabled"
      theme="ios"
      :disabled="false"
      onColor="#FFE2D1"
      offColor="#FFE2D1"
      thumbColor="#CA56AD"
      borderColor="#FFE2D1"
      width="48"
      height="24"
      margin="3"
      @input="onToggle"
    />

    <p class="pl-4 text-sm font-medium text-gray-700 dark:text-gray-500" v-if="text">{{ text }}</p>
    <p class="pl-4 text-sm font-medium text-gray-700 dark:text-gray-500" v-if="!text && slotNotEmpty"><slot></slot></p>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    text: String,
    initialValue: Boolean,
  },
  components: {
    // Switch
  },
  computed: {
    slotNotEmpty() {
      return (
        this.$slots.default &&
        this.$slots.default().findIndex((o) => o.type !== Comment) !== -1
      )
    },
  },
  data() {
    return {
      enabled: true,
    }
  },
  mounted() {
    if (this.initialValue === true || this.initialValue === false) {
      this.enabled = this.initialValue
    }
  },
  methods: {
    onToggle(v) {
      // console.log('toggle: onToggle', v)
      this.$emit("input", v)
    },
  },
}
</script>
