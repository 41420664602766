import { render, staticRenderFns } from "./BackgroundIcons.vue?vue&type=template&id=987a974c&scoped=true&"
import script from "./BackgroundIcons.vue?vue&type=script&lang=js&"
export * from "./BackgroundIcons.vue?vue&type=script&lang=js&"
import style0 from "./BackgroundIcons.vue?vue&type=style&index=0&id=987a974c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "987a974c",
  null
  
)

export default component.exports