export default {
  computed: {},
  created() {
    this.$store.dispatch("client/componentCreated", this.$options.name)
  },
  mounted() {
    this.$store.dispatch("client/componentMounted", this.$options.name)
  },
  methods: {},
}
