import tailwindConfig from '../../../tailwind.config.js'

// initial state
const state = () => ({
  loadingState: [],
  // scrollY: 0,
  viewport: {
    width: null,
    height: null,
    size: null, // approximated breakpoint
    currentBreakpoint: null,
    breakpoints: {
      xs: 375,
      sm: parseInt(tailwindConfig.theme.screens.sm.replace('px','')),
      md: parseInt(tailwindConfig.theme.screens.md.replace('px','')),
      lg: parseInt(tailwindConfig.theme.screens.lg.replace('px','')),
      xl: parseInt(tailwindConfig.theme.screens.xl.replace('px','')),
      '2xl': parseInt(tailwindConfig.theme.screens['2xl'].replace('px','')),
    },
  },
})

// getters
const getters = {
  allComponentsMounted: (state) => {
    return state.loadingState.findIndex((c) => c.state != "mounted") === -1
  },
}

// actions
const actions = {
  componentCreated({commit, state}, payload) {
    commit("setComponentState", {component: payload, state: "created"})
  },
  componentMounted({commit, state}, payload) {
    commit("setComponentState", {component: payload, state: "mounted"})
  },
  updateViewportSize({commit, state, rootState}, payload) {
    let vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    let vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )

    commit("setViewportWidth", vw)
    commit("setViewportHeight", vh)

    // breakpoint
    let cb = "xl"
    if (vw < state.viewport.breakpoints.xl) cb = "lg"
    if (vw < state.viewport.breakpoints.lg) cb = "md"
    if (vw < state.viewport.breakpoints.md) cb = "sm"
    if (vw < state.viewport.breakpoints.sm) cb = "xs"
    if (vw < state.viewport.breakpoints.xs) cb = "_def"
    commit("setCurrentBreakpoint", cb)

    // viewport size
    let vs = null
    if (["_def", "xs"].includes(cb)) vs = "mobile"
    if (["sm", "md"].includes(cb)) vs = "tablet"
    if (["lg", "xl"].includes(cb)) vs = "desktop"
    commit("setCurrentViewportSize", vs)
  },
}

// mutations
const mutations = {
  setComponentState: (state, payload) => {
    if (payload.state == "created") {
      state.loadingState.push({
        component: payload.component,
        state: payload.state,
      })
    } else {
      let index = state.loadingState.findIndex(
        (c) => c.component == payload.component
      )
      state.loadingState[index].state = payload.state
    }
  },
  // setScrollY: (state, payload) => {
  //   // // console.log('setScrollY()', payload)
  //   state.scrollY = payload
  // },
  setViewportWidth(state, payload) {
    state.viewport.width = payload
  },
  setViewportHeight(state, payload) {
    state.viewport.height = payload
  },
  setCurrentBreakpoint(state, payload) {
    state.viewport.currentBreakpoint = payload
  },
  setCurrentViewportSize(state, payload) {
    state.viewport.size = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
