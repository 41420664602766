import firebase from "firebase/compat/app"
// import "firebase/compat/auth"
import "firebase/compat/firestore"
// import "firebase/compat/functions"
// import * as firebaseui from "firebaseui"

let useEmulator = process.env.VUE_APP_USE_EMULATOR === "true" || false

// Firebase
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}
firebase.initializeApp(firebaseConfig)

// var fbsFn = firebase.functions() //firebase.app().functions('europe-west1')
var fbsDb = firebase.firestore()
// var fbsAuth = firebase.auth()

fbsDb.settings({ignoreUndefinedProperties: true})

// connect to emulator
if (process.env.VUE_APP_ENVIRONMENT == "local_dev" && useEmulator) {
  // console.log("using emulator functions")
  console.log("using emulator firestore")
  // console.log("using emulator auth")

  // fbsFn.useEmulator("localhost", 5001)
  fbsDb.useEmulator("localhost", 8080)
  // fbsAuth.useEmulator("http://localhost:9099/", {disableWarnings: true})
}

// exports
export const fb = firebase
export const db = fbsDb
// export const fn = fbsFn
// export const fbui = firebaseui
// export const auth = fbsAuth
