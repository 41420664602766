import { render, staticRenderFns } from "./OurMission.vue?vue&type=template&id=622c3019&scoped=true&"
import script from "./OurMission.vue?vue&type=script&lang=js&"
export * from "./OurMission.vue?vue&type=script&lang=js&"
import style0 from "./OurMission.vue?vue&type=style&index=0&id=622c3019&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622c3019",
  null
  
)

export default component.exports