<template>
  <div class="cont relative overflow-hidden z-30">
    <BaseContainer class="container text-center">
      <h2 class="text-cdlpink">Our Mission</h2>
      <p class="pt-3">
        To inspire crypto community to help animals&nbsp;in&nbsp;need
      </p>
      <p class="pt-3">
        To make as big impact in the real world as&nbsp;possible
      </p>
      <p class="pt-3">To make animals happy</p>
      <p class="pt-3">To drive a change</p>
      <img class="cloud" src="../../assets/images/cloud.png" />
    </BaseContainer>
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"

export default {
  name: "OurMission",
  mixins: [mixinInitLoader],
  components: {},
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";
.cont {
  padding: 200px 0 200px 0;
  position: relative;

  .container {
    position: relative;

    .cloud {
      position: absolute;
      top: -100px;
      left: calc(-800px + 80vw);
      right: 0;
      margin: 0 auto;
      width: calc(800px + 30vw);
      // height: 500px;
      max-width: initial;
      z-index: -1;

      @media (min-width: @bp-md) {
        width: calc(800px + 50vw);
        left: calc(-800px + 50vw);
      }

      @media (min-width: @bp-xl) {
        width: 100%;
        left: 0;
      }
    }
  }
}
</style>
