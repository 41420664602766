<template>
  <GrassBox>
        <h2 class="text-white pt-4 pb-6 md:pb-10 lg:pb-12">Our Call</h2>
        <!-- boxes -->
        <div class="boxes grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          <ExpandableBox v-for="box in boxes" :content="box" :key="box.id" />
        </div>
  </GrassBox>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import ExpandableBox from '@/components/common/ExpandableBox'
import GrassBox from '@/components/common/GrassBox'

export default {
  name: "WhoWeAre",
  mixins: [mixinInitLoader],
  components: {
    ExpandableBox,
    GrassBox
  },
  data() {
    return {
      boxes: [{
        id: '1',
        heading: 'DONATING CRYPTOCURRENCY',
        contentShort: 'Blockchain technology enables fast, effective, and transparent transactions/transfers of funds. No middlemen, the donation proceeds as intended. We encourage and help charities to adopt crypto, while also enabling those who can not receive crypto funds.',
        contentExpanded: ''
      },{
        id: '2',
        heading: 'CALLING UPON SOCIALLY RESPONSIBLE SUBJECTS / COMPANIES',
        contentShort: 'We address and appeal to socially responsible real-world companies and crypto projects looking for opportunities to help.',
        contentExpanded: ''
      },{
        id: '3',
        heading: 'ENGAGING PUBLIC',
        contentShort: 'Engaging public interest, as well as allowing a public overview of our donations through blockchain open data available to everyone. ',
        contentExpanded: ''
      },{
        id: '4',
        heading: 'CREATING BRIDGES TO USE CRYPTO FOR GOOD',
        contentShort: 'Providing opportunity to use their “native” currency while helping others in an easy and transparent way.',
        contentExpanded: ''
      }]
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";
</style>
