<template>
  <div class="cont relative overflow-hidden pb-12 z-30">
    <BaseContainer class="text-left md:text-center">
      <div class="how-we-help py-24">
        <img class="cloud-top" src="../../assets/images/cloud-left.png" />
        <h2 class="text-cdlblack">HOW WE HELP</h2>
        <p class="mt-4 leading-relaxed">
          Our charitable efforts and activities were originally backed up by private funds, but to extend our efforts, we rely on multiple sources of income from everyone willing to help, as well as sources connected to crypto.
        </p>
        <img class="cloud-bottom" src="../../assets/images/cloud-right.png" />
      </div>

      <!-- boxes -->
      <div
        class="
          boxes
          grid
          gap-20
          md:grid-cols-3
          text-left
          md:flex-row md:flex-wrap md:justify-between
        "
      >
        <div
          class="box"
          v-for="item in items"
          :key="item.id"
        >
          <img :src="item.icon" class='float-right -mt-12' :class='item.iconClass' />
          <div class="bg-cdlpeach-dark rounded-xl p-4 md:h-full">
            <h3 class="text-2xl pb-2 text-cdlpink">{{ item.title }}</h3>
            <p class="leading-relaxed">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import iconFund from "@/assets/icon-fund.svg?raw"
import iconNft from "@/assets/icon-nft.svg?raw"
import iconMerch from "@/assets/icon-merch.svg?raw"

export default {
  name: "WhoWeHelp",
  mixins: [mixinInitLoader],
  components: {
    iconFund,
    iconNft,
    iconMerch,
  },
  data() {
    return {
      items: [
        {
          id: "fund",
          title: "Impact Fund",
          text: "Transparent crypto fund established by Cuddle.Me. Where we do collect all the donations. Enables public review, for everyone interested to make sure the funds are reaching the right place.",
          icon: iconFund,
          iconClass: 'w-16 mr-6 transform rotate-6'
        },
        {
          id: "nft",
          title: "Future NFT projects",
          text: "One of the tools to raise money for our fund are NFT projects we plan to release in the near future. The proceeds from the sales of the collectibles will be directed to the fund.",
          icon: iconNft,
          iconClass: 'w-16 mr-4 transform -rotate-6'
        },
        {
          id: "merch",
          title: "Merch",
          text: "We made some nice Cuddle.Merch so you can support our efforts, and cuddle everywhere you go! Crypto e-shop is coming soon!",
          icon: iconMerch,
          iconClass: 'w-20 mr-2 transform rotate-6'
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";
.cont {
  .how-we-help {
    position: relative;
    .cloud-top {
      position: absolute;
      top: 30px;
      left: 90vw;
      width: 320px;
      animation: animate-cloud;
      animation-duration: 60s;
      animation-delay: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      z-index: -1;
    }

    .cloud-bottom {
      position: absolute;
      width: 380px;
      z-index: 1;
      left: 90vw;
      bottom: 0px;
      animation: animate-cloud;
      animation-duration: 60s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      z-index: -1;
    }
  }

  @media (min-width: @bp-md) {
    .boxes {
      gap: 1rem;

      .box {
        flex-basis: 49%;
      }
    }
  }

  .animal-shelters {
    // .dog-1 {}
  }

  @keyframes animate-cloud {
    from {
      position: absolute;
      left: 95vw;
    }
    to {
      position: absolute;
      left: -90%;
    }
  }

  .boxes {
    .box {
      // background-color: theme('colors.cdlpeach.dark');
      // border-radius: 30px;
    }
  }
}
</style>
