<template>
  <div class='app'>
    <transition name="view" appear>
      <div>
        <!-- <transition name="fade-slow" mode="out-in">
          <SplashScreen v-show="showSplashScreen" />
        </transition> -->
        

        <Menu key="menu" />
        <Socials key="socials" />
        <transition name="fade" mode="out-in">
          <router-view :key="$route.name" />
        </transition>
        <!-- <JoinDiscord /> -->
      </div>
    </transition>
  </div>
</template>

<script>
import SplashScreen from "@/components/SplashScreen"
import Menu from "@/components/common/Menu"
import Socials from "@/components/common/Socials"
import JoinDiscord from "./components/common/JoinDiscord"
import BackgroundIcons from "./components/Landing/BackgroundIcons.vue"

export default {
  name: "App",
  components: {
    SplashScreen,
    Menu,
    Socials,
    JoinDiscord,
    BackgroundIcons,
  },
  data() {
    return {
      showSplashScreen: true,
      splashScreenFadeOutSpeed: 4500,
    }
  },
  computed: {
    allComponentsMounted() {
      if (this.$route.path != '/') true // we're not waiting on subpages
      return this.$store.getters["client/allComponentsMounted"]
    },
    backgroundIconsZIndex() {
      if (this.showSplashScreen) return 201
      else return 0
    },
  },
  watch: {
    allComponentsMounted: {
      deep: true,
      handler(newValue, oldValue) {
        // console.log(newValue, oldValue)
        if (newValue === true) {
          this.hideSplashScreen()
        }
      },
    },
  },
  mounted() {
    if (this.$route.path != '/') this.showSplashScreen = false
  },
  methods: {
    hideSplashScreen() {
      setTimeout(() => {
        this.showSplashScreen = false
      }, this.splashScreenFadeOutSpeed)
    },
  },
}
</script>

<style lang="less">
@import "./themes/variables.less";
@black: #2E3240;
@pink: #D551AD;
@bg: #ffe2d1;
@green: #97D26F;

.app {
  width: 100%;
  overflow-x: hidden;
}

.view-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.view-enter-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
  transition-delay: 0.5s;
}

.view-enter,
.view-leave-to {
  opacity: 0;
}

.view-enter-to,
.view-leave {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition-duration: 1.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-slow-enter,
.fade-slow-leave-active {
  opacity: 0;
}

@keyframes animate-cloud {
    from {
        position: absolute;
        left: 95vw;
    }
    to {
        position: absolute;
        left: -90%;
    }
}

@keyframes animate-cloud2 {
    from {
        position: absolute;
        left: 90vw;
    }
    to {
        position: absolute;
        left: -80%;
    }
}

@media(min-width:769px) and (max-width:1200px) {
    .pd-mobile {
        padding: 0 30px;
        box-sizing: border-box;
    }
    .main-app {
        overflow: hidden;
    }
    .menu .nav-menu {
        margin-right: 30px;
    }
}

@media(max-width:768px) {
    .pd-mobile {
        padding: 0 15px;
        box-sizing: border-box;
    }
    .main-app {
        overflow: hidden;
    }
    .menu, .main-social {
        display: none;
    }
    .menu-mobile {
        width: 30px;
        height: 22px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
        span {
            display: block;
            width: 100%;
            background: @black;
            height: 3px;
            border-radius: 100px;
        }
    }
    .modal_vx {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 0;
        z-index: 9;
        animation: modal 0.3s forwards;
        &::before {
            content: "";
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: @pink;
            z-index: 9;
        }
        &_content {
            position: relative;
            z-index: 9;
            padding: 15px;
            .modal-line {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 30px;
                img {
                    width: 80px;
                }
                .close-modal {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    cursor: pointer;
                    span {
                        display: block;
                        width: 100%;
                        background: @black;
                        height: 4px;
                        border-radius: 100px;
                        &:nth-child(1) {
                            transform: rotate(45deg);
                            position: relative;
                            top: 15px;
                        }
                        &:nth-child(2) {
                            transform: rotate(-45deg);
                            position: relative;
                            top: -11px;
                        }
                    }
                }
            }
        }
        .menu {
            display: block;
            .nav-menu {
                margin-right: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;
                a {
                    font-family: 'Fredoka One', cursive;
                    font-size: 40px;
                    text-align: center;
                    line-height: 60px;
                    margin-left: 0;
                    color: #fff;
                    margin-bottom: 20px;
                }
            }
            .btn-global {
                padding: 19px 30px;
            }
        }
        .main-social {
            display: block;
            position: relative;
            top: 0;
            margin-top: 30px;
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                li {
                    a {
                        width: 62px;
                        height: 62px;
                        img {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }

    @keyframes modal {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes modalleft {
        from {
            transform: translateX(190px);
        }
        to {
            transform: translateX(0px);
        }
    }


    .main-header {
        padding: 15px;
        .logo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            img {
                width: 80px;
            }
        }
    }
}
</style>
