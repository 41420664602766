<template>
  <div class='w-full flex flex-col items-start relative z-10'>
    <span class="font-bold text-cdlblack font-heading text-xl pl-6">Pre-sale start</span>
    <div class="box px-3 pb-4 pt-6 inline-flex flex-row justify-start items-center mt-1">
      <div class="flex flex-row justify-start">
        <div class="flex flex-col justify-center items-center uppercase text-xs text-center" v-for="time in times" :key="time.id">
          <div class="number w-16 h-16 mx-1 flex items-center justify-center text-2xl"><span>{{time.time}}</span></div><span class='number-label pt-1'>{{time.text}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinInitLoader from '@/mixins/initLoader'
export default {
  name: "Countdown",
  mixins: [mixinInitLoader],
  components: {},
  data() {
    return {
      endTime: '2022-01-18T06:00:00',
      times: [
        { id: 0, text: "days", time: 1 },
        { id: 1, text: "hours", time: 1 },
        { id: 2, text: "minutes", time: 1 },
        { id: 3, text: "seconds", time: 1 }
      ],
    }
  },
  created() {
    this.updateTimer();
    this.timeinterval = setInterval(this.updateTimer, 1000);
  },
  methods: {
    
    updateTimer() {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
      } else {
        clearTimeout(this.timeinterval);
         this.progress = 0;
      }
    },
    getTimeRemaining() {
      let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
     if(t >= 0){
      this.times[3].time = Math.floor(t / 1000 % 60); //seconds
      this.times[2].time = Math.floor(t / 1000 / 60 % 60); //minutes
      this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
      this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
       }else {
         this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
         this.progress = 0;
       }
    },
  },
  
}
</script>

<style lang="less" scoped>
.box {
  background: rgba(180, 144, 143, 0.31);
  backdrop-filter: saturate(180%) blur(10px);
  border-radius: 10px;
}

.number {
  background: #3D3D3D;
border-radius: 4px;

span {
  color: #fff;
}


}
span.number-label {
  color: #C0A096;
}
</style>
