<template>
  <div class="cont relative overflow-hidden pb-20 z-30">
    <div class="grass-wrap">
      <BaseContainer class="text-left md:text-center py-12 md:pt-0 md:pb-24">
        <slot></slot>
      </BaseContainer>
    </div>
    <GrassBottom class="grass-bottom" />
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import GrassBottom from "@/assets/grass-bottom.svg?inline"

export default {
  name: "GrassBox",
  mixins: [mixinInitLoader],
  components: {
    GrassBottom,
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";

.cont {
  position: relative;
  .grass-wrap {
    background-color: theme("colors.cdlgrass.DEFAULT");
  }

  .grass-bottom {
    position: absolute;
    right: -50px;
    bottom: 10px;
    width: 300vw;
    z-index: -1;

    @media (min-width: @bp-md) {
      right: 0;
      bottom: 1vw;
      width: 180vw;
    }

    @media (min-width: @bp-lg) {
      right: 0;
      bottom: calc(8vw - 10px);
      width: 140vw;
    }
  }

  @media (min-width: @bp-lg) {
    padding-bottom: 200px;
  }
}
</style>
