<template>
  <div class='pt-10 overflow-hidden'>
    <Hero />
    
    <div class='relative z-20'>
      <Audiences />
      <WhoWeHelp />
      <HowWeHelp />
      <!-- <OurMission /> -->
      <RoadmapCarousel />
      <JoinCuddle/>
      <TheTeam class='pb-10' />
      <Footer />
    </div>
  </div>
</template>

<script>
import mixinInitLoader from '@/mixins/initLoader'
import Menu from '@/components/common/Menu'
import Hero from "@/components/Landing/Hero"
import Audiences from "@/components/Landing/Audiences"
import WhoWeHelp from "@/components/Landing/WhoWeHelp"
import HowWeHelp from "@/components/Landing/HowWeHelp"
import OurMission from '@/components/Landing/OurMission'
import JoinCuddle from '../components/Landing/JoinCuddle'
import Footer from "@/components/common/Footer"
import RoadmapCarousel from '../components/Landing/RoadmapCarousel'
import TheTeam from "@/components/NFT/TheTeam"



export default {
  name: "Home",
  mixins: [mixinInitLoader],
  components: {
    Menu,
    Hero,
    Audiences,
    WhoWeHelp,
    HowWeHelp,
    OurMission,
    JoinCuddle,
    Footer,
    RoadmapCarousel,
    TheTeam
  },
  data() {
    return {}
  },
}
</script>
