import Vue from "vue"
import Router from "vue-router"
import Landing from "../views/Landing.vue"

Vue.use(Router)

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/ukraine",
    alias: ['/ua'],
    name: "Ukraine",
    component: () =>
      import(/* webpackChunkName: "ukraine" */ "../views/Ukraine.vue"),
  },
  {
    path: "/fund",
    name: "Impact Fund",
    component: () =>
      import(/* webpackChunkName: "impactfund" */ "../views/ImpactFund.vue"),
  },
  {
    path: "/partners",
    name: "Partners",
    component: () =>
      import(/* webpackChunkName: "partners" */ "../views/Partners.vue"),
  },
  {
    path: "/story",
    name: "Story",
    component: () =>
      import(/* webpackChunkName: "story" */ "../views/Story.vue"),
  },
  {
    path: "/opendata",
    name: "Open Data",
    component: () =>
      import(/* webpackChunkName: "opendata" */ "../views/OpenData.vue"),
  },
  {
    path: "/private-presale",
    name: "Private Presale",
    component: () =>
      import(
        /* webpackChunkName: "privatepresale" */ "../views/PrivatePresale.vue"
      ),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import(/* webpackChunkName: "faq" */ "../views/Faq.vue"),
  },
  {
    path: "/widget",
    name: "Widget",
    component: () =>
      import(/* webpackChunkName: "widget" */ "../views/Widget.vue"),
  },
  {
    path: "/dogaday",
    name: "Dog A Day",
    component: () =>
      import(/* webpackChunkName: "dog-a-day" */ "../views/DogADay.vue"),
  },
  {
    path: "/nft",
    name: "NFT",
    component: () =>
      import(/* webpackChunkName: "nft" */ "../views/NFT.vue"),
  },
  {
    path: "/nft/collection1",
    name: "Collection 1",
    component: () =>
      import(
        /* webpackChunkName: "nft-collection-1" */ "../views/NftCollection1.vue"
      ),
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  let s = {
    behavior: "smooth",
  }

  if (savedPosition) {
    s = {...s, ...savedPosition}
  } else {
    s.x = 0
    s.y = 0
  }

  if (to.hash) {
    s.selector = to.hash
  }

  return s
}

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior,
})

export default router
