<template>
<BaseContainer>
    <section class="theteam">
        <div class="container">
            <img class="cloud-team" src="@/assets/images/cloud.png" alt="Cloud">
            <h3 class="theteam__title">The Team</h3>
            <p class="theteam__description">We are a team of crypto heads who have been in this space for a very long time. Wanting more than just another crypto project, we merged our love for degen crypto, furry animals & warm cuddling to create a project that drives change and starts a whole movement.</p>
            <div class="row">
                <div class="col-md-4">
                    <div class="theteam__item">
                        <img src="@/assets/team/avatar-cuddles.svg?raw" alt="Cuddles" v-scrollanimation>
                        <div class="anima-two" v-scrollanimation>
                            <h4>Cuddles</h4>
                            <h5>Founder</h5>
                            <p>The chief visionnaire with general oversight over the enterprise. True crypto OG, buying his first a decade ago. Always ahead, he likes things to get in the move. Especially those making sense, or different. The one being able to dig beneath the surface.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theteam__item delay-one">
                        <img src="@/assets/team/avatar-jbulb.svg?raw" alt="J-Bulb" v-scrollanimation>
                        <div class="anima-two" v-scrollanimation>
                            <h4>J-Bulb</h4>
                            <h5>Co-founder</h5>
                            <p>One of those types with continuous creative thoughts and focus on invention. Many passed or missed the things he turned into the gold later. Master of vision, with ability to see behind the corner. Open and always entertaining type of soul.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theteam__item delay-two">
                        <img src="@/assets/team/avatar-wobbles.svg?raw" alt="Wobbles" v-scrollanimation>
                        <div class="anima-two" v-scrollanimation>
                            <h4>Wobbles</h4>
                            <h5>Co-founder</h5>
                            <p>The first and the last doggo who had quit the same day at the very first job he got. Being able to learn all the tricks the day he was employed, he established his own company the same evening and wobbled away. Isn’t that a career? You bet your bone it is!</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theteam__item">
                        <img src="@/assets/team/avatar-neverdividebyzero.svg?raw" alt="!/0" v-scrollanimation>
                        <div class="anima-two" v-scrollanimation>
                            <h4>!/0</h4>
                            <h5>Keyboard Operator</h5>
                            <p>The rennaissance type of breed, well educated, well informed. Multitalented with various skills, knowing his job well. Zenmaster of digital realms. With his height he is able to see the things in the big picture. Many other doggos come to get the advice from him.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theteam__item delay-one">
                        <img src="@/assets/team/avatar-checkmate.svg?raw" alt="Checkmate" v-scrollanimation>
                        <div class="anima-two" v-scrollanimation>
                            <h4>Checkmate</h4>
                            <h5>Artist</h5>
                            <p>The master artist. Coffee lover. Chess player. Always a step ahead. Risen for a purpose. Art overloaded persona, his sense and emotions combined with the power of pentool push the limit to gain access to bigger rewards.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theteam__item delay-two">
                        <img src="@/assets/team/avatar-philbone.svg?raw" alt="Phil-Bone" v-scrollanimation>
                        <div class="anima-two" v-scrollanimation>
                            <h4>Phil-Bone</h4>
                            <h5>Fund Manager</h5>
                            <p>A true friend, and sensitive type of soul with wide interests across multiple disciplines including psychology, spiritual development and others. Aside from marketing and practical skills, his interests are music and music editing, as well as nature.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theteam__item delay-one">
                        <img src="@/assets/team/avatar-woofyd.svg?raw" alt="Woofy D" v-scrollanimation>
                        <div class="anima-two" v-scrollanimation>
                            <h4>Woofy D</h4>
                            <h5>Content Generator</h5>
                            <p>The master of barking. Loves Doggo Poetry. One of his private hobbies is plastic modelling. Specialization: Second World War Bones 1:72. Big airplane enthusiast. Artist, musician, producer and also sound engineer for years, with a daily job as a copywriter.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theteam__item">
                        <img src="@/assets/team/avatar-jayclick.svg?raw" alt="Jay Click" v-scrollanimation>
                        <div class="anima-two" v-scrollanimation>
                            <h4>Jay Click</h4>
                            <h5>Advisor</h5>
                            <p>Jay’s life wish was to become a doggo, but he also reaches heights where only eagles dare. A visionary starting in tech and software dev, with credit in many pre-crypto startups. An early BTC investor since 2012.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</BaseContainer>
</template>

<script>


export default {
    name: 'TheTeam',

}

</script>

<style lang="less">

@black: #2E3240;
@pink: #D551AD;
@bg: #ffe2d1;
@green: #97D26F;

.col-md-4 {
        position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
}

.theteam {
    position: relative;
    // padding: 100px 0 60px;
    .cloud-team {
        position: absolute;
        top: -30px;
        left: -120px;
        right: 0;
        margin: 0 auto;
        width: 1470px;
        z-index: -1;
    }
    &__title {
        font-size: 40px;
        line-height: 65px;
        color: @pink;
        text-align: center;
        margin-bottom: 20px;
    }
    &__description {
        font-family: 'Filson Pro';
        font-size: 25px;
        line-height: 45px;
        text-align: center;
        color: @black;
        width: 1000px;
        max-width: 100%;
        margin: 0 auto 120px;
    }
    &__item {
        text-align: center;
        margin-bottom: 60px;
        img {
            height: 340px;
            margin-bottom: -60px;
            opacity: 0;
            display: inline;
            &.enter {
                animation-name: bounceIn;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
        }
        .anima-two {
            opacity: 0;
            &.enter {
                animation-name: bottomIn;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
        }
        h4 {
            font-size: 22px;
            line-height: 30px;
            color: @pink;
        }
        h5 {
            font-family: 'Filson Pro';
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 28px;
            color: #000;
            font-weight: 500;
        }
        p {
            font-family: 'Filson Pro';
            font-size: 16px;
            line-height: 24px;
            color: #000;
            width: 320px;
            max-width: 100%;
            margin: 0 auto;
        }
        @media(min-width:768px) {
            &.delay-one {
                img {
                    &.enter {
                        animation-delay: 0.5s;   
                    }
                }
                .anima-two {
                    &.enter {
                        animation-delay: 0.5s;
                    }
                }
            }
            &.delay-two {
                img {
                    &.enter {
                        animation-delay: 1s;   
                    }
                }
                .anima-two {
                    &.enter {
                        animation-delay: 1s;
                    }
                }
            }
        }
    }
}

:root {
  --animate-duration: 1.5s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}
.theteam__item {
  animation-duration: 1s;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

@keyframes bottomIn {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@media(max-width:768px) {
    .theteam {
        position: relative;
        padding: 0 15px;
        &__title {
            font-size: 32px;
            line-height: 42px;
        }
        &__description {
            font-size: 22px;
            line-height: 36px;
        }
        .cloud-team {
            position: absolute;
            top: -94px;
            left: -1000px;
            right: 0;
            margin: 0 auto;
            width: 1470px;
            z-index: -1;
            width: 1700px;
            max-width: 1700px;
        }
    }
}
@media(min-width:769px) and (max-width:1200px) {
    .theteam {
        padding: 0 30px;
    }
}
</style>