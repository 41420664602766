<template>
  <component
    :is="kind"
    :type="naked ? '' : 'button'"
    class="cursor-pointer"
    :class="[
      {'px-3 pt-2 pb-1 text-sm': size == 'xs' && !naked},
      {'px-6 pt-3 pb-2 text-base': size == 'base' && !naked},
      {'px-6 pt-3 pb-3 text-xl': size == 'xl' && !naked},
      {'px-4 pt-3 pb-2 text-md md:px-6 md:pt-3 md:pb-2 md:text-base xl:px-6 xl:pt-3 xl:pb-3 xl:text-xl 2xl:px-8 2xl:pt-4 2xl:pb-4 2xl:text-2xl': size == 'auto' && !naked},
      {
        'inline-flex items-center border  font-medium rounded-full shadow-sm focus:outline-none transition-all duration-500 ease-in-out':
          !naked,
      },
      {'border-cdlblack': outline},
      {
        'text-white bg-cdlblack hover:bg-cdlblack-dark border-transparent':
          !outline && !naked,
      },
      {'bg-uablue hover:bg-uablue-dark text-uayellow': ukraine},
      classes,
    ]"
    @click="onClick"
  >
    <slot></slot>
  </component>
</template>

<script>
import {event as gtagEvent} from "vue-gtag"

export default {
  name: "Button",
  props: {
    url: {
      type: String,
    },
    page: {
      type: String,
    },
    to: {
      type: String,
    },
    event: {
      type: String,
    },
    size: {
      type: String, // xs | base
      default: "base",
    },
    naked: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    kind: {
      type: String,
      default: "button", // button | a
    },
    ukraine: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: "",
    },
  },
  components: {},
  methods: {
    onClick(e) {
      console.log("onClick", this.page)
      if (this.page || this.to) {
        let page = this.page ? this.page : this.to
        this.$router.push(page)
      } else if (this.url) window.open(this.url, "_blank")

      if (this.event) gtagEvent(this.event)
      this.$emit("click", e)
    },
  },
}
</script>
