var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.kind,{tag:"component",staticClass:"cursor-pointer",class:[
    {'px-3 pt-2 pb-1 text-sm': _vm.size == 'xs' && !_vm.naked},
    {'px-6 pt-3 pb-2 text-base': _vm.size == 'base' && !_vm.naked},
    {'px-6 pt-3 pb-3 text-xl': _vm.size == 'xl' && !_vm.naked},
    {'px-4 pt-3 pb-2 text-md md:px-6 md:pt-3 md:pb-2 md:text-base xl:px-6 xl:pt-3 xl:pb-3 xl:text-xl 2xl:px-8 2xl:pt-4 2xl:pb-4 2xl:text-2xl': _vm.size == 'auto' && !_vm.naked},
    {
      'inline-flex items-center border  font-medium rounded-full shadow-sm focus:outline-none transition-all duration-500 ease-in-out':
        !_vm.naked,
    },
    {'border-cdlblack': _vm.outline},
    {
      'text-white bg-cdlblack hover:bg-cdlblack-dark border-transparent':
        !_vm.outline && !_vm.naked,
    },
    {'bg-uablue hover:bg-uablue-dark text-uayellow': _vm.ukraine},
    _vm.classes ],attrs:{"type":_vm.naked ? '' : 'button'},on:{"click":_vm.onClick}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }