<template>
  <!-- background scattered images -->
  <div class="bg">
    <div id="scattered-images-canvas" :style="{zIndex}"></div>
    <img src="#" class="image" hidden="hidden" id="scattered-image-template" />
  </div>
</template>

<script>
export default {
  name: "BackgroundIcons",
  props: {
    zIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  data() {
    return {}
  },
  mounted() {
    this.randomScatterImages()

    this.robodog = this.$anime({
      targets: ".rotating-icon",
      rotateZ: 360,
      easing: "linear",
      loop: true,
      duration: 10000,
    })
  },
  methods: {
    randomScatterImages() {
      var canvas = document.querySelector("#scattered-images-canvas")
      var icon_template = document.querySelector("#scattered-image-template")
      var icon_width = 20
      var icon_height = 20
      var the_images = ["/img/bone.svg", "/img/paw.svg", "/img/tennisball.svg"]
      var pickRandomImage = function () {
        var i = Math.floor(Math.random() * the_images.length)
        return the_images[i]
      }
      var total_number_of_images = 20
      var max_height = canvas.offsetHeight - icon_height
      var max_width = canvas.offsetWidth - icon_width
      var randomCoordinate = function () {
        var r = []
        var x = Math.floor(Math.random() * max_width)
        var y = Math.floor(Math.random() * max_height)
        r = [x, y]
        return r
      }
      var randomRotate = function () {
        let min = 0
        let max = 360
        return Math.floor(Math.random() * (max - min + 1)) + min
      }
      var createImage = function () {
        var node = icon_template.cloneNode(true)
        var xy = randomCoordinate()
        node.removeAttribute("id")
        node.removeAttribute("hidden")
        node.style.top = xy[1] + "px"
        node.style.left = xy[0] + "px"
        node.style.transform = `rotate(${randomRotate()}deg)`
        node.classList.add("rotating-icon")
        node.setAttribute("src", pickRandomImage())
        canvas.appendChild(node)
      }
      for (var i = 0; i < total_number_of_images; i++) {
        createImage()
      }
    },
  },
}
</script>

<style lang="less" scoped>
#scattered-images-canvas {
  // entire site
  height: 100vh;
  width: 100vw;
  bottom: 0;
  position: fixed;

  // hero only
  // height: 100%;
  // position: absolute;

  top: 0;
  right: 0;
  left: 0;

  .image {
    position: absolute;
    z-index: 2;

    svg {
      * {
        fill: #fae9e1;
      }
    }
  }
}
</style>
