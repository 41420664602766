var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"},[_c('div',{staticClass:"\n          shadow\n          overflow-hidden\n          border-b border-gray-200\n          dark:border-gray-900\n          sm:rounded-lg\n        "},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200 dark:divide-gray-600"},[_c('thead',{staticClass:"bg-gray-50 dark:bg-gray-800"},_vm._l((_vm.table.head),function(row,theadTrIndex){return _c('tr',{key:theadTrIndex},_vm._l((row),function(col,theadTrTdIndex){return _c('th',{key:theadTrTdIndex,class:[
                  _vm.tc.head.th,
                  ("text-" + (col.align ? col.align : 'right')) ]},[_vm._v(" "+_vm._s(col.c)+" ")])}),0)}),0),(_vm.loading)?_c('tbody',[_c('tr',[_c('td',{staticClass:"p-6",attrs:{"colspan":_vm.table.head[0].length}},[_c('BaseLoading',{attrs:{"size":"s"}})],1)])]):_vm._e(),(!_vm.loading && _vm.table.body.length === 0)?_c('tbody',[_c('tr',[_c('td',{staticClass:"p-6 text-center",attrs:{"colspan":_vm.table.head[0].length}},[_vm._v(" no cuddles here 😔 ")])])]):_vm._e(),(!_vm.loading)?_c('tbody',{staticClass:"\n              bg-white\n              dark:bg-gray-700\n              divide-y divide-gray-200\n              dark:divide-gray-600\n            "},_vm._l((_vm.table.body),function(row,tbodyTrIndex){return _c('tr',{key:tbodyTrIndex,staticClass:"hover:bg-gray-50 dark:hover:bg-gray-800"},_vm._l((row),function(col,tbodyTrTdIndex){return _c('td',{key:tbodyTrTdIndex,class:[
                  _vm.tc.body.td,
                  {'text-gray-300 dark:text-gray-500': col.ignored},
                  {'text-gray-500 dark:text-gray-300': !col.ignored},
                  {'w-0 pr-0': col.warning},
                  {'font-mono': col.mono},
                  ("text-" + (_vm.table.head[0][tbodyTrTdIndex].align
                      ? _vm.table.head[0][tbodyTrTdIndex].align
                      : 'right')) ]},[_c('span',{class:[{'cursor-pointer hover:text-gray-700 dark:hover:text-gray-50': col.routerLink || col.href},{'cursor-copy hover:text-gray-300': col.clickToCopy}],on:{"click":function($event){return _vm.onClick(col)}}},[(col.truncate)?_c('span',[_vm._v(_vm._s(_vm.truncateAddress(col.c)))]):(col.warning === true)?_c('span',[_vm._v("⚠️")]):(col.warning === false)?_c('span'):(!col.c && col.c !== 0)?_c('span',[_vm._v("?")]):_c('span',[_vm._v(_vm._s(col.c))])])])}),0)}),0):_vm._e(),(!_vm.loading)?_c('tfoot',_vm._l((_vm.table.foot),function(row,tfootTrIndex){return _c('tr',{key:tfootTrIndex,staticClass:"bg-gray-50 dark:bg-gray-800"},_vm._l((row),function(col,tfootTrTdIndex){return _c('td',{key:tfootTrTdIndex,class:[
                  _vm.tc.foot.td,
                  ("text-" + (_vm.table.head[0][tfootTrTdIndex].align
                      ? _vm.table.head[0][tfootTrTdIndex].align
                      : 'right')) ]},[_vm._v(" "+_vm._s(col.c)+" ")])}),0)}),0):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }