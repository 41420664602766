<template>
  <div class="">
    <div class="md:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select
        id="tabs"
        name="tabs"
        class="
          block
          w-full
          focus:ring-indigo-500 focus:border-indigo-500
          border-gray-300
          rounded-md
        "
      >
        <option
          v-for="tab in tabs"
          :key="tab.name"
          :selected="tab.current"
          @select="onSelect(tab)"
        >
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden md:inline-block">
      <div class="font-bold text-center text-sm font-medium text-gray-700 dark:text-gray-500" v-if="label">
        {{ label }}
      </div>
      <nav class="flex space-x-4 bg-cdlcream-dark rounded-md p-1" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :href="tab.href"
          :class="[
            tab.current
              ? 'bg-cdlpink text-white'
              : 'text-gray-500 hover:text-gray-700',
            'px-3 py-2 font-medium text-sm rounded-md cursor-pointer',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
          @click="onSelect(tab)"
        >
          {{ tab.name }}
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: {
      type: Array,
    },
    label: {
      type: String,
      default: "",
    },
  },
  components: {},
  methods: {
    onSelect(tab) {
      this.$emit("select", tab)
    },
  },
}
</script>
