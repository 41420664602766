<template>
  <!-- <div class="socials flex flex-col justify-center h-screen fixed top-0 left-0"> -->
  <div class="wrap flex flex-col items-center justify-center py-3 gap-0 xl:py-4 transition-all duration-500 ease-in-out" :style="style">
    <BaseButton
      class="mb-2"
      kind="a"
      naked
      event="socials_twitter_click"
      :url="uri.twitter"
      target="_blank"
      ><SocialTwitterIcon class="w-4 sm:w-7 sm:p-1 m-2"
    /></BaseButton>
    <!-- <BaseButton
      class="mb-2"
      kind="a"
      naked
      event="socials_discord_click"
      :url="uri.discord"
      target="_blank"
      ><SocialDiscordIcon class="w-4 sm:w-7 sm:p-1 m-2"
    /></BaseButton> -->
    <BaseButton
      class="mb-2"
      kind="a"
      naked
      event="socials_instagram_click"
      :url="uri.instagram"
      target="_blank"
      ><SocialInstagramIcon class="w-4 sm:w-7 sm:p-1 m-2"
    /></BaseButton>
    <!-- <BaseButton
      class="mb-2"
      kind="a"
      naked
      event="socials_facebook_click"
      :url="uri.facebook"
      target="_blank"
      ><SocialFacebookIcon class="w-4 sm:w-7 sm:p-1 m-2"
    /></BaseButton> -->
    <BaseButton
      class="mb-2"
      kind="a"
      naked
      event="socials_medium_click"
      :url="uri.medium"
      target="_blank"
      ><SocialMediumIcon class="w-4 sm:w-7 sm:p-1 m-2"
    /></BaseButton>
    <!-- <BaseButton
      class="mb-2"
      kind="a"
      naked
      event="socials_tiktok_click"
      :url="uri.tiktok"
      target="_blank"
      ><SocialTikTokIcon class="w-4 sm:w-7 sm:p-1 m-2"
    /></BaseButton> -->
    <!-- <BaseButton
      class="mb-2"
      kind="a"
      naked
      event="socials_telegram_main_click"
      :url="uri.telegramMain"
      target="_blank"
      ><SocialTelegramIcon class="w-4 sm:w-7 sm:p-1 m-2"
    /></BaseButton> -->
    <!-- <BaseButton
      class="mb-2"
      kind="a"
      naked
      event="socials_telegram_announcement_click"
      :url="uri.telegramAnnouncement"
      target="_blank"
      ><SocialTelegramIcon class="w-4 sm:w-7 sm:p-1 m-2"
    /></BaseButton> -->
    <!-- <BaseButton
      kind="a"
      naked
      event="socials_youtube_click"
      :url="uri.youtube"
      target="_blank"
      ><SocialYoutubeIcon class="w-4 sm:w-7 sm:p-1"
    /></BaseButton> -->
  </div>
  <!-- </div> -->
</template>

<script>
import SocialTwitterIcon from "@/assets/icon-twitter.svg?inline"
import SocialInstagramIcon from "@/assets/icon-instagram.svg?inline"
import SocialFacebookIcon from "@/assets/icon-facebook.svg?inline"
import SocialDiscordIcon from "@/assets/icon-discord.svg?inline"
import SocialMediumIcon from "@/assets/icon-medium.svg?inline"
import SocialTikTokIcon from "@/assets/icon-tiktok.svg?inline"
import SocialTelegramIcon from "@/assets/icon-telegram.svg?inline"
import SocialYoutubeIcon from "@/assets/icon-youtube.svg?inline"

export default {
  name: "Socials",
  components: {
    SocialTwitterIcon,
    SocialInstagramIcon,
    SocialFacebookIcon,
    SocialDiscordIcon,
    SocialMediumIcon,
    SocialTikTokIcon,
    SocialTelegramIcon,
    SocialYoutubeIcon,
  },
  data() {
    return {
      visible: true,
      style: {
        right: null
      },
      uri: {
        discord: process.env.VUE_APP_URI_DISCORD,
        twitter: process.env.VUE_APP_URI_TWITTER,
        instagram: process.env.VUE_APP_URI_INSTAGRAM,
        facebook: process.env.VUE_APP_URI_FACEBOOK,
        medium: process.env.VUE_APP_URI_MEDIUM,
        tiktok: process.env.VUE_APP_URI_TIKTOK,
        telegramMain: process.env.VUE_APP_URI_TELEGRAM_MAIN,
        telegramAnnouncement: process.env.VUE_APP_URI_TELEGRAM_ANNOUNCEMENT,
        youtube: process.env.VUE_APP_URI_YOUTUBE,
      },
      prevScrollPos: 0
    }
  },
  created() {
    this.enableAutohide()
  },
  methods: {
    enableAutohide() {
      window.addEventListener("scroll", (e) => {
        this.scrolled(e)
      })
    },
    scrolled(e) {
      let currentScrollPos = window.pageYOffset
      // console.log('scrolled:', currentScrollPos, this.prevScrollPos, e)
      if (this.prevScrollPos >= currentScrollPos) {
        if (!this.visible) this.show()
      } else {
        if (this.visible && this.prevScrollPos >= 0) this.hide()
      }
      this.prevScrollPos = currentScrollPos
    },
    show() {
      // console.log('show()')
      this.style.right = "10px"
      this.visible = true
    },
    hide() {
      // console.log('hide()')
      this.style.right = "-60px"
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.wrap {
  position: fixed;
  top: 180px;
  right: 10px;
  width: 40px;
  display: flex;
  flex-direction: column;
  // background-color: rgba(0, 0, 0, 0.25);
  // backdrop-filter: saturate(180%) blur(10px);
  z-index: 100;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (min-width: theme("screens.xl")) {
    width: 70px;
  }

  a {
    background-color: theme('colors.cdlblack.DEFAULT');
    border-radius: 50%;
    transition: background 250ms ease-in-out 0ms;

    &:hover {
      background-color: theme('colors.cdlpink.DEFAULT');
    }

    svg {
      * {
        fill: #fff;
      }
    }
  }
}
</style>
