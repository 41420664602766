<template>
  <!-- logo & title & text -->
  <div class="pt-24 pb-0 logo-title-text-wrap">
    <div class="relative">
      <!-- title + subtitle -->
      <div class="pt-12 md:pt-20 lg:pt-32 z-20 relative">
        <transition name="fade-slow" mode="out-in">
          <h2
            class="
              font-heading
              text-5xl
              font-bold
              text-cdlpink
              tracking-normal
              leading-tight
              lg:leading-snug
              lg:text-7xl
            "
            v-html="title"
          ></h2>
        </transition>
        <p
          class="
            font-text
            leading-snug
            lg:leading-normal
            mt-4

            pr-14
            text-2xl
            lg:text-3xl
            text-gray-800
            md:max-w-md
            lg:max-w-3xl
          "
          v-html="subtitle"
        ></p>
        <BaseButton
          class="mt-8"
          size="auto"
          @click="onButtonClick"
          :ukraine="cta.ukraine"
          :event="cta.event"
          v-if="cta"
          >{{ cta.label }}</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroIntro",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    button: {
      type: Object,
    },
    cta: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {
    onButtonClick() {
      if (this.cta.to) {
        this.$router.push(`/${this.cta.to}`)
      } else if (this.cta.url) {
        if (this.cta.url.startsWith('http')) {
          window.open(this.cta.url, "_blank")
        } else {
          window.open(this.cta.url, "_self")
        }
        
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";

.logo-title-text-wrap {
  // min-height: 40vh;
  // padding-top: 40px;
  // padding-bottom: calc(100px + 25vw);

  @media (min-width: @bp-md) {
    // padding-bottom: calc(200px + 5vw);
  }

  @media (min-width: @bp-lg) {
    // padding-bottom: 300px;
  }
}
</style>
