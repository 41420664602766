// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/grass-footer.svg?raw");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".cont[data-v-50ceb206]{background-color:#97d26f}@media (min-width:1024px){.cont[data-v-50ceb206]{position:relative}}.cont .container[data-v-50ceb206]{position:relative}@media (min-width:1024px){.cont .container[data-v-50ceb206]{position:static}}.cont .container[data-v-50ceb206]:before{content:\"\";position:absolute;left:-180px;right:0;top:-30px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100%;width:300vw;height:40px;padding-top:8vw;background-position-x:center;background-position-y:top}@media (min-width:768px){.cont .container[data-v-50ceb206]:before{width:100vw;left:0}}@media (min-width:1024px){.cont .container[data-v-50ceb206]:before{top:-40px}}", ""]);
// Exports
module.exports = exports;
