<template>
  <div class="cont relative overflow-hidden pb-12 z-30">
    <BaseContainer class="text-left md:text-center">
      <div class="how-we-help py-24">
        <img class="cloud-top" src="../../assets/images/cloud-left.png" />
        <h2 class="text-cdlblack">WHO WE HELP</h2>
        <p class="mt-4 leading-relaxed">
        Our efforts and main concerns are directed toward four main subjects:
        </p>
        <img class="cloud-bottom" src="../../assets/images/cloud-right.png" />
      </div>

      <!-- boxes -->
      <div
        class="
          boxes
          grid
          gap-20
          md:grid-cols-2
          text-left
          md:flex-row md:flex-wrap md:justify-between
        "
      >
        <div
          class="box"
          v-for="item in items"
          :key="item.id"
        >
          <img :src="item.icon" class='float-right -mt-12' :class='item.iconClass' />
          <div class="bg-cdlpeach-dark rounded-xl p-4 md:h-full">
            <h3 class="text-2xl pb-2 text-cdlpink">{{ item.title }}</h3>
            <p class="leading-relaxed">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"
import iconShelter from "@/assets/icon-shelter.svg?raw"
import iconVet from "@/assets/icon-vet.svg?raw"
import iconRnd from "@/assets/icon-rnd.svg?raw"
import iconHumanitarian from '@/assets/icon-humanitarian.svg?raw'

export default {
  name: "WhoWeHelp",
  mixins: [mixinInitLoader],
  components: {
    iconShelter,
    iconVet,
    iconRnd,
    iconHumanitarian
  },
  data() {
    return {
      items: [
        {
          id: "1",
          title: "Urgent cases",
          text: "Handpicked, both Cuddle.me & community-verified urgent cases across the entire world. Involved in humanitarian help where it's needed.",
          icon: iconHumanitarian,
          iconClass: 'w-20 mr-4 transform rotate-6'
        },
        {
          id: "2",
          title: "Research and Development",
          text: "Supporting research teams looking for affordable cancer treatment to offer working medical alternatives available for less money.",
          icon: iconRnd,
          iconClass: 'w-16 mr-8 transform -rotate-6'
        },
        {
          id: "3",
          title: "Shelters",
          text: "Both Cuddle.Me and community-verified animal shelters doing their job well no matter where they are from.",
          icon: iconShelter,
          iconClass: 'w-20 mr-4 transform -rotate-6'
        },
        {
          id: "4",
          title: "Vets and their patients",
          text: "Helping less fortunate, or disadvantaged pet owners to finance their pet's expensive medical treatment",
          icon: iconVet,
          iconClass: 'w-20 mr-4 transform rotate-6'
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";
.cont {
  .how-we-help {
    position: relative;
    .cloud-top {
      position: absolute;
      top: 30px;
      left: 90vw;
      width: 320px;
      animation: animate-cloud;
      animation-duration: 60s;
      animation-delay: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      z-index: -1;
    }

    .cloud-bottom {
      position: absolute;
      width: 380px;
      z-index: 1;
      left: 90vw;
      bottom: 0px;
      animation: animate-cloud;
      animation-duration: 60s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      z-index: -1;
    }
  }

  @media (min-width: @bp-md) {
    .boxes {
      gap: 1rem;

      .box {
        flex-basis: 49%;
      }
    }
  }

  .animal-shelters {
    // .dog-1 {}
  }

  @keyframes animate-cloud {
    from {
      position: absolute;
      left: 95vw;
    }
    to {
      position: absolute;
      left: -90%;
    }
  }

  .boxes {
    
    .box {
      // background-color: theme('colors.cdlpeach.dark');
      // border-radius: 30px;
    }
  }
}
</style>
