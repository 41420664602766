<template>
  <div>
    <div
      class="
        logo-text
        fixed
        inset-0
        flex flex-col
        justify-center
        items-center
        text-center
      "
    >
      <CuddleLogo class="w-48" />
      <span class="pt-12 text-xl font-heading text-cdlblack"
        >loading cuddles</span
      >
    </div>

    <div
      class="
        splashscreen
        w-screen
        h-screen
        flex flex-col
        justify-center
        items-center
        inset-0
        fixed
      "
    ></div>
  </div>
</template>

<script>
import CuddleLogo from "@/assets/cuddle_logo_color.svg?inline"

export default {
  name: "SplashScreen",
  components: {
    CuddleLogo,
  },
}
</script>

<style lang="less" scoped>
.logo-text {
    z-index: 99999;
  }
.splashscreen {
  // z-index: 999999;
  z-index: 200;
  background: rgba(255, 242, 235);
  // background: rgba(255, 242, 235, 0.24);
  // backdrop-filter: saturate(180%) blur(20px);

  
}
</style>
