import {firestoreAction} from "vuexfire"
import {db, fb} from "@/globals"
import mixinGlobal from '@/mixins/mixinGlobal'

const state = () => ({
  partners: [],
  loadingState: {
    partners: "init",
  },
})

// getters
const getters = {
  totalPartners: (state, getters, rootState) => {
    let total = 0
    state.partners.map((p) => {
      if (!p.demo) total++
    })
    return total
  },

  totalDonationsCount: (state, getters, rootState) => {
    let total = 0
    if (state.partners && state.partners.length > 0) {
      state.partners.map((p) => {
        if (!p.demo && !isNaN(parseInt(p.totalDonationsCount)))
          total += parseInt(p.totalDonationsCount)
      })
    }
    return total
  },

  totalETH: (state, getters, rootState) => {
    return mixinGlobal.methods.roundAmount(
      state.partners.reduce(function (a, b) {
        if (b.demo) return a
        else return a + b.totalDonations.ETH
      }, 0)
    )
  },

  totalUSD: (state, getters, rootState) => {
    if (rootState.exchangeRates.loadingState.exchangeRates != "loaded") return
    return mixinGlobal.methods.roundAmount(
      state.partners.reduce(function (a, b) {
        if (b.demo) return a
        else return a + mixinGlobal.methods.convert(b.totalDonations.ETH, 'ETH', 'USD', 3, rootState.exchangeRates.exchangeRates.pairs)
      }, 0),
      0
    )
  },
}

// actions
const actions = {
  bind: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      console.log('binding partners')
      commit("setLoadingState", {instance: "partners", state: "loading"})

      await bindFirestoreRef("partners", db.collection(`partners`).where('demo', '==', false), {
        wait: false,
      })

      commit("setLoadingState", {instance: "partners", state: "loaded"})
      return
    }
  ),
  unbind: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("partners")
  }),
}

// mutations
const mutations = {
  setLoadingState(state, payload) {
    state.loadingState[payload.instance] = payload.state
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
