<template>
  <div
    class="
      top-0
      right-0
      left-0
      fixed
      w-full
      transition-all
      duration-500
      ease-in-out
    "
    :style="menuStyle"
    style="z-index: 5000"
  >
    <div class="banner p-2 text-sm text-center" v-if="banner">
      Support Ukraine with one of the collectibles from the NFT collection <router-link class="underline" to="/ukraine"
        >"Heroes of Ukraine"</router-link
      > - 100% of the proceeds go directly to the Ministry of the health of Ukraine.
    </div>
    <div class="">
      <!-- banner -->
      <!-- <div class='bg-cdlpink w-full'>
        <router-link class="block px-2 py-2 md:px-6 text-white text-center" to="/dogaday">Now saving A Dog a Day! <span class='underline'>contribute & win</span></router-link>
      </div> -->
      <!-- <div><CuddleLogo class="w-16" /></div> -->
      <div
        class="
          p-0
          pl-3
          py-4
          flex flex-row
          justify-between
          items-center
          transition-all
          duration-500
          ease-in-out
        "
      >
        <div
          class="
            md:pr-20
            2xl:pr-0
            flex flex-row
            items-center
            justify-between
            md:justify-between
            w-full
            md:max-w-7xl
            mx-auto
          "
        >
          <router-link class="block px-2" to="/">
            <!-- <CuddleLogo class="w-8 h-8 md:h-14 md:w-14"/> -->
            <LottieAnimation
              style="width: initial; height: initial"
              class="cuddle-logo"
              path="./lottie/logo-cuddle.json"
              :width="logo.width"
              :height="logo.height"
              v-if="logo && logo.width"
            />
          </router-link>
          <div
            class="
              menu-items
              flex flex-row
              items-center
              overflow-x-scroll
              md:overflow-auto
              pl-4
              pt-1
            "
          >
            <router-link
              class="whitespace-nowrap text-xl py-3 px-3"
              v-for="link in links"
              :key="link.to"
              :to="link.to"
              >{{ link.label }}</router-link
            >

            <!-- <BaseButton event="connect_wallet_click" size="xs" class='md:hidden'>wallet</BaseButton> -->
            <!-- <BaseButton event="connect_wallet_click" size="xl" class='hidden md:block'>connect wallet</BaseButton> -->
          </div>
        </div>
      </div>
      <!-- <div>wallet</div> -->
    </div>
  </div>
</template>

<script>
import CuddleLogo from "@/assets/cuddle_logo_icon_color.svg?inline"
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"
// import LogoCuddle from '@/assets/lottie/logo-cuddle.json'

export default {
  name: "Menu",
  components: {
    CuddleLogo,
    LottieAnimation,
    // LogoCuddle
  },
  data() {
    return {
      // LogoCuddle,
      menuVisible: true,
      menuStyle: {
        top: null,
      },
      prevScrollPos: 0,
      links: [
        {
          to: "fund",
          label: "Impact Fund",
        },
        {
          to: "opendata",
          label: "Open Data",
        },
        {
          to: "nft",
          label: "NFTs",
        },
      ],
    }
  },
  computed: {
    logo() {
      if (["desktop"].includes(this.viewport.size)) {
        return {
          width: 135,
          height: 72,
        }
      } else if (["tablet"].includes(this.viewport.size)) {
        return {
          width: 105,
          height: 56,
        }
      } else {
        return {
          width: 75,
          height: 40,
        }
      }
    },
    banner() {
      if (this.$route.name == "Ukraine") return false
      else return true
    },
    offset() {
      if (this.banner) return 180 //130
      else return 150 //100
    },
    viewport() {
      return this.$store.state.client.viewport
    },
  },
  created() {
    this.enableAutohide()
    this.setMenuBackground()
  },
  methods: {
    enableAutohide() {
      window.addEventListener("scroll", (e) => {
        this.scrolled(e)
        this.setMenuBackground()
      })
    },
    scrolled(e) {
      let currentScrollPos = window.pageYOffset
      // console.log('scrolled:', currentScrollPos, this.prevScrollPos, e)
      if (this.prevScrollPos >= currentScrollPos) {
        if (!this.menuVisible) this.showMenu()
      } else {
        if (this.menuVisible && this.prevScrollPos >= 0) this.hideMenu()
      }
      this.prevScrollPos = currentScrollPos
    },
    setMenuBackground() {
      let currentScrollPos = window.pageYOffset
      if (currentScrollPos <= this.offset) {
        this.menuStyle.background = "none"
        this.menuStyle.backdropFilter = "none"
      } else {
        this.menuStyle.background = "rgba(255,226,209,0.25)"
        this.menuStyle.backdropFilter = "saturate(180%) blur(10px)"
      }
    },
    showMenu() {
      // console.log('showMenu()')
      this.menuStyle.top = "0"
      this.menuVisible = true
    },
    hideMenu() {
      // console.log('hideMenu()')
      this.menuStyle.top = `-${this.offset}px`
      this.menuVisible = false
      this.menuOpen = false
    },
  },
}
</script>

<style lang="less" scoped>
.banner {
  color: #ffd500;
  background: #005bbb;
}
.menu-items {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
}
</style>
