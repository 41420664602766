<template>
  <div class="main-carrossel-road">
    <div class="container max-w-none md:max-w-7xl md:px-4">
      <div class="main-carrossel-road__content" v-if="items && items.length">
        <h2 class="main-carrossel-road__title">Mission Reports</h2>
        <p class="main-carrossel-road__description"></p>
        <carousel
          :navText="['', '']"
          :margin="50"
          :responsive="{
            0: {items: 1},
            769: {items: 2, stagePadding: 30},
            991: {items: 2, startPosition: 0, stagePadding: 50},
            1500: {items: 3, startPosition: 0, stagePadding: 50},
          }"
        >
          <!-- <div class="item" v-for="item in itemsx" :key="item.id">
            <p class='status text-gray-400'>{{item.status}}</p>
            <p class="number">{{ item.id }}</p>
            <h4 class="title">{{ item.title }}</h4>
            <p class="description">{{ item.description }}</p>
          </div> -->

          <div class="item" v-for="item in itemsSorted" :key="item.id" >
            <p class="status text-gray-400">{{ item.publishedDate }}</p>
            <p class="number">{{ item.id }}</p>
            <h4 class="title">{{ item.title }}</h4>
            <div class='image'><img :src="item.firstImage" /></div>
            <!-- <p class="description">{{ item.contentPlain.slice(0, 250) }}...</p> -->
            <span class='text-cdlpink underline mt-4 block' href="" @click="openArticle(item.link)">read more</span>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel"
import {db} from "@/globals"
import _ from 'lodash'

export default {
  name: "CarrosselRoad",
  components: {carousel},
  data() {
    return {
      items: [],
      itemsx: [
        {
          id: 1,
          title: "Cut out the middleman!",
          status: "ongoing",
          description:
            "Enable those we support (shelters, vets, patients)  to receive crypto funds independently. Cut out the middleman. Make all donations visible to everyone through blockchain.",
        },
        {
          id: 2,
          title: "Weekly donations",
          status: "ongoing",
          description:
            "We are donating through our social media platform campaigns weekly to our partners. Please help us to raise more money for them by simply sharing, tagging or commenting!",
        },
        {
          id: 3,
          title: "10 NFTs of the saved souls",
          status: "in progress",
          description:
            "Our partners need to actually help doggos that would otherwise be euthanized or live a wretched life without your support. Before tackling the last milestone, we're committing to document 10 cases & giving these partner vets & shelters NFTs of these saved souls.",
        },
        {
          id: 4,
          title: "Partnerships",
          status: "ongoing",
          description:
            "Healing the world together. Ongoing partnerships with bigger projects that have an impact over a profit mentality, and follow socially responsible values.",
        },
        {
          id: 5,
          title: "Impact Boost",
          status: "planning",
          description:
            "We're introducing a new NFT fundraising mechanism that allows contributors to boost the impact they cause! While optional, this will allow you to drive change while the Impact Boost gets directly reflected within your NFT.",
        },
        {
          id: 6,
          title: "Launch the pack!",
          status: "planning",
          description:
            "Our first collection are cool crypto doggos from all around the world. 8 breeds, 552 traits, 3000 unique NFTs.",
        },
        {
          id: 7,
          title: "Launch of The Cuddle Show",
          status: "in progress",
          description:
            "Fasten your seatbelts! The humorous and entertaining character of our show will throw you off your stool with laughter.",
        },
        {
          id: 8,
          title: "Sandbox prize",
          status: "planning",
          description:
            "We are hiding a huge prize on our Sandbox land. Our NFTs are the first key to the “Golden Egg”.",
        },
        {
          id: 9,
          title: "The Cuddle Store",
          status: "in progress",
          description:
            "Launch our Cuddle Merch. Old school is gone, with its over-priced Italian name brands that everyone now has. What is truly fashionable is the hottest Cuddle merch that's also a statement. Who doesn't want to wear our signature hoodie with Cuddle Me on it",
        },
        {
          id: 10,
          title: "Expand charity through celebrities ",
          status: "planning",
          description:
            "At this point, we will have already driven a huge impact on the charity world. There are many good-hearted celebrities out there aligned with our vision. We will expand our good cause with the help of celebrities using our Impact Boost.",
        },
      ],
    }
  },
  computed: {
    itemsSorted() {
      return _.orderBy(this.items, ['published'], ['desc'])
    },
  },
  created() {
    this.fetchArticles()
  },
  methods: {
    async fetchArticles() {
      // console.log('fetchArticles()')
      let response = await db.collection("mediumArticles").get()
      response.docs.forEach((r, index) => {
        
        let data = r.data()
        // console.log(data)
        let publishedDate = new Date(data.published)
        let article = data
        let id = index + 1
        article.publishedDate = publishedDate.toLocaleString()
        article.id = id
        this.items.push(data)
      })
    },
    openArticle(link) {
      window.open(link, "_blank")
    },
  },
}
</script>

<style lang="less">
@black: #2e3240;
@pink: #d551ad;
@bg: #ffe2d1;
@green: #97d26f;

.main-carrossel-road {
  position: relative;
  margin: 80px 0;
  display: flex;
  justify-content: center;
  .owl-nav {
    box-sizing: border-box;
    margin: 0;
    position: relative;
    top: 40px;
    padding: 0 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .owl-prev,
    .owl-next {
      font-size: 32px !important;
      color: #fff !important;
      background: transparent !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .owl-prev {
      background: url("~@/assets/images/arrow_left.svg?raw") center/cover
        no-repeat !important;
      width: 25px;
      height: 34px;
    }
    .owl-next {
      background: url("~@/assets/images/arrow_right.svg?raw") center/cover
        no-repeat !important;
      width: 25px;
      height: 34px;
    }
  }
  .owl-dots {
    span {
      width: 30px !important;
      height: 30px !important;
      background: #fff !important;
      border-radius: 50% !important;
      position: relative;
      &:before {
        content: "";
        width: 15px;
        height: 15px;
        left: 7.5px;
        top: 7.5px;
        border-radius: 50%;
        transition: all ease-in-out 0.3s;
        position: absolute;
        z-index: 9;
      }
    }
    .active {
      span {
        &:before {
          background: @pink;
        }
      }
    }
  }
  .owl-stage-outer {
    padding-bottom: 30px;
  }

  .owl-stage {
    display: flex;
    flex-direction: row;
  }

  .owl-item {
    // height: 100%;
    flex-grow: 1;
  }

  &__content {
    background: @pink;
    position: relative;
    padding: 40px 0 30px;
    border-radius: 30px;
  }
  &__title {
    font-size: 40px;
    line-height: 65px;
    color: #fff;
    margin-bottom: 10px;
    padding: 0 50px;
  }
  &__description {
    width: 900px;
    max-width: 100%;
    font-family: "Filson Pro";
    font-size: 25px;
    line-height: 45px;
    color: #fff;
    margin-bottom: 60px;
    padding: 0 50px;
    box-sizing: border-box;
  }
  .item {
    border-radius: 23px;
    background: #fff;
    padding: 50px;
    text-align: center;
    min-height: 350px;
    box-shadow: 15px 15px 0 0 #000;
    height: 100%;
    @media (min-width: 1500px) {
      min-height: 350px;
    }
    .number {
      font-size: 45px;
      line-height: 65px;
      color: @black;
      margin-bottom: 5px;
    }
    .title {
      color: @pink;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 10px;
    }
    .description {
      font-family: "Filson Pro";
      font-size: 16px;
      line-height: 28px;
      color: #000;
    }
  }
}

@media (max-width: 768px) {
  .main-carrossel-road .item {
    padding: 50px 25px;
  }
  .main-carrossel-road .owl-item {
    padding-right: 15px;
    box-sizing: border-box;
  }
  .main-carrossel-road {
    &__title {
      font-size: 32px;
      line-height: 65px;
      padding: 0;
    }
    &__description {
      font-size: 22px;
      line-height: 34px;
      padding: 0;
    }
    &__content {
      padding: 40px 15px 30px;
      border-radius: 0px;
    }
    .owl-nav {
      top: 35px;
      padding: 0;
    }
  }
}
</style>
