<template>
  <div class="pl-4 pr-4 md:max-w-7xl mx-auto" :class="[]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {},
  components: {},
  methods: {},
}
</script>
