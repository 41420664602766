<template>
  <div
    class="
      flex flex-row
      justify-center
      items-end
      fixed
      bottom-3
      left-0
      right-0
      z-50
    "
  >
    <BaseButton
      kind="a"
      naked
      :url="uri.discord"
      event="socials_discord_fixed_click"
      target="_blank"
      class="
        flex flex-row
        items-center
        justify-center
        bg-cdlblack
        hover:bg-cdlblack-dark
        focus:ring-cdlblack-light
        transition-all
        duration-500
        ease-in-out
        rounded-full
        text-white
        px-4
        py-3
        md:px-9 md:py-6 md:text-2xl
        uppercase
      "
      ><SocialDiscordIcon
        class="w-6 mr-3 md:w-10 md:mr-5 text-white children:fill-current"
      />
      join discord</BaseButton
    >
  </div>
</template>

<script>
import SocialDiscordIcon from "@/assets/icon-discord.svg?inline"

export default {
  name: "Socials",
  components: {
    SocialDiscordIcon,
  },
  data() {
    return {
      uri: {
        discord: process.env.VUE_APP_URI_DISCORD,
      },
    }
  },
}
</script>
