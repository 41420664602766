<template>
  <div class="cont">
    <BaseContainer class="container pb-10 pt-28">
      <img
        class="absolute left-0 right-0 -top-10 w-36 mx-auto -mt-10"
        :src="imgDogBowl"
      />
      <h5 class="text-white text-2xl text-center">
        A Little Help Can Make A Big Change
      </h5>
      <p class='text-white text-center pt-2 pb-4'>We leave no one in need behind!</p>
      <div class="text-center pt-10">
        <!-- <BaseButton
          ><IconDiscord class="text-white children:fill-current w-8 mr-4" />Join
          Us on Discord</BaseButton
        > -->
        <BaseButton @click="onClickTweet()" event="footer_tweet_about_us_click"><IconTwitter class='text-white children:fill-current w-6 mr-4' />Tweet about us!</BaseButton>
      </div>
      <p class="text-white pt-10 text-center">
        © Copyright 2022 - All Rights Reserved.
      </p>
    </BaseContainer>
  </div>
</template>

<script>
// import CuddleLogoIcon from "@/assets/cuddle_logo_icon_color.svg"
import CuddleLogo from "@/assets/cuddle_logo_white.svg?inline"
import IconDiscord from "@/assets/icon-discord.svg?inline"
import IconTwitter from '@/assets/icon-twitter.svg?inline'
import ImageDogBowl from "@/assets/images/svg/raw/dog-bowl.svg?raw"

export default {
  name: "Footer",
  components: {
    // CuddleLogoIcon,
    CuddleLogo,
    IconDiscord,
    IconTwitter,
    ImageDogBowl,
  },
  data() {
    return {
      uri: {
        discord: process.env.VUE_APP_URI_DISCORD,
      },
      tweetText: 'Join the effort of Cuddle.me impact fund! From donations to shelters, to co-funding animal treatment, to support of research and development of effective animal medicine.'
    }
  },
  computed: {
    imgDogBowl() {
      return ImageDogBowl
    },
  },
  methods: {
    onClickTweet() {
      window.open(`https://twitter.com/intent/tweet?text=${this.tweetText}`, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";
.cont {
  background-color: theme("colors.cdlgrass.DEFAULT");
  @media (min-width: @bp-lg) {
      position: relative;
    }

  .container {
    position: relative;
    @media (min-width: @bp-lg) {
      position: initial;
    }

    &:before {
      content: "";
      position: absolute;
      left: -180px;
      right: 0;
      top: -30px;
      background: url("../../assets/grass-footer.svg?raw");
      background-repeat: no-repeat;
      background-size: 100%;
      width: 300vw;
      height: 40px;
      padding-top: 8vw;
      background-position-x: center;
      background-position-y: top;

      @media (min-width: @bp-md) {
        width: 100vw;
        left: 0;
      }

      @media (min-width: @bp-lg) {
        top: -40px;
        
      }
    }
  }
}
</style>
